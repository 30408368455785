<!--
 * @Author: your name
 * @Date: 2019-11-11 14:06:50
 * @LastEditTime: 2019-11-29 17:19:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \chefstation_restaurant\src\components\chat\chat.vue
 -->
<!--  -->
<template>
  <section class="chat">
    <el-container>
      <!-- 左侧聊天列表 -->
      <el-aside>
        <!-- 搜索 -->
        <section class="search">
          <el-input
              :placeholder="$t('shop-goods.A29F44@search')"
              size="small"
              style="width:180px;"
              prefix-icon="el-icon-search"
              v-model="keywords"
              @input="getChatList"
          ></el-input>
        </section>
        <section class="userList">
          <section class="sliding">
            <div
                :class="[{'checked':room_id==v.room_id?true:false},'user']"
                v-for="(v,i) in ChatList"
                :key="i"
                @click="clickChatList(v,i)"
            >
              <div class="user-time" style="font-size:12px;white-space:nowrap;">{{ v.latest_time }}</div>
              <section class="user-main">
                <el-badge :value="v.badge" :hidden="!badgeShow[i]" class="item">
                  <el-avatar shape="square" :size="50" :src="v.room_icon"></el-avatar>
                </el-badge>
                <div class="user-info">
                  <span class="name">{{ v.list_name }}</span>
                  <span class="user-msg">{{ v.new_content }}</span>
                </div>
              </section>
            </div>
          </section>
        </section>
      </el-aside>
      <el-container>
        <!-- 聊天头部 -->
        <el-header
            height="60px"
            :style="{'box-shadow':Boolean(room_id)?'0 8px 5px -5px rgba(0, 0, 0, .1)':'none'}"
        >{{ room_name }}
        </el-header>
        <!-- 聊天内容 -->
        <el-main v-loading="loading">
          <ul v-if="Boolean(room_id)" class="msgContent" @scroll="handleScroll">
            <li v-for="(v,i) in msgList" :key="i">
              <div style="text-align: center;" :data-msgId="v.rec_id">{{ v.sendtime }}</div>
              <!-- 聊天对象 -->
              <div class="userLeft" v-if="v.display=='other'">
                <el-avatar
                    shape="square"
                    :size="40"
                    :src="v.avatar"
                    style="margin-right:15px;min-width:40px"
                ></el-avatar>
                <!-- 消息 -->
                <div class="msg" v-if="v.push_type=='chat_msg'" v-html="v.content"></div>
                <!-- 图片 -->
                <div class="msg-img" v-if="v.push_type=='chat_img'">
                  <el-image
                      class="flex"
                      style="max-width: 100px; max-height: 100px; min-width:50px;min-height:50px;"
                      :preview-src-list="[v.content]"
                      :src="v.content"
                      fit="cover"
                  >
                    <div slot="error" class="image-slot">
                      {{ $t('aboutus.0DBD9D@Failed_to_load') }}
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </div>
                <!-- 样本 -->
                <el-card class="sampleForm" v-if="v.push_type=='req_sample'">
                  <h3
                      style="text-align: center;margin-bottom: 15px;"
                  >{{ $t('aboutus.B4F293@Request_sample') }}</h3>
                  <section class="content">
                    <section class="column">
                      <h5 style="text-align:center">{{ $t('aboutus.72AB93@Product_Details') }}</h5>
                      <ul>
                        <li>
                          <span class="name">{{ $t('restaurant_inventory.0009CF@product_name') }}</span>:
                          <span>{{ v.content.goods_name }}</span>
                        </li>
                        <li>
                          <span
                              class="name"
                          >{{ $t('restaurant_supplier_addSupProduct.16EBEC@price') }}</span>:
                          <span>{{ v.content.price }}</span>
                        </li>
                        <li>
                          <span
                              class="name"
                          >{{ $t('supplier-produceManage-publishProduct.4E6A29@Commodity_unit') }}</span>:
                          <span>{{ v.content.unit_str }}</span>
                        </li>
                      </ul>
                    </section>
                    <section class="column">
                      <h5 style="text-align:center">{{ $t('aboutus.2AD773@Restaurant_Details') }}</h5>
                      <ul>
                        <li>
                          <span
                              class="name"
                          >{{ $t('backend_authrity_authrityList.C90CD7@restaurant_tab') }}</span>:
                          <span>{{ v.content.restaurant_name }}</span>
                        </li>
                        <li>
                          <span class="name">{{ $t('restaurant_buy_genner.FE6497@store') }}</span>:
                          <span>{{ v.content.shop_name }}</span>
                        </li>
                        <li>
                          <span
                              class="name"
                          >{{ $t('restaurant_supplier_supplierList.E84BAF@address') }}</span>:
                          <span>{{ v.content.addr }}</span>
                        </li>
                        <li>
                          <span
                              class="name"
                          >{{ $t('restaurant_supplier_addsuppluer.0C63A5@linkman') }}</span>:
                          <span>{{ v.content.linkman }}</span>
                        </li>
                      </ul>
                    </section>
                  </section>
                </el-card>
                <!-- 报价 -->
                <el-card class="priceForm" v-if="v.push_type=='req_price'">
                  <h3
                      style="text-align: center;margin-bottom: 15px;"
                  >{{ $t('aboutus.375CE0@Request_a_special_offer') }}</h3>
                  <section style="margin-bottom: 10px;">
                    <h5 style="text-align:center">{{ $t('aboutus.9301F0@Quotation_Information') }}</h5>
                    <ul>
                      <li>
                        <span class="name">{{ $t('aboutus.2E2D92@Quantity_of_Order') }}</span>:
                        <span>{{ v.content.order_num }}</span>
                      </li>
                      <li>
                        <span class="name">{{ $t('aboutus.F5021B@Target_Price') }}</span>:
                        <span>{{ v.content.req_price }}</span>
                      </li>
                      <li>
                        <span class="name">{{ $t('aboutus.035886@Repeat_Orders') }}</span>:
                        <span>{{
                            v.content.reorder == 'Yes' ? $t('aboutus.980E0D@yes') : $t('aboutus.A03514@no')
                          }}</span>
                      </li>
                    </ul>
                  </section>
                  <section style=" display: flex;justify-content: space-between;">
                    <section class="column">
                      <h5 style="text-align:center">{{ $t('aboutus.72AB93@Product_Details') }}</h5>
                      <ul>
                        <li>
                          <span class="name">{{ $t('restaurant_inventory.0009CF@product_name') }}</span>:
                          <span>{{ v.content.goods_name }}</span>
                        </li>
                        <li>
                          <span
                              class="name"
                          >{{ $t('restaurant_supplier_addSupProduct.16EBEC@price') }}</span>:
                          <span>{{ v.content.price }}</span>
                        </li>
                        <li>
                          <span
                              class="name"
                          >{{ $t('supplier-produceManage-publishProduct.4E6A29@Commodity_unit') }}</span>:
                          <span>{{ v.content.unit_str }}</span>
                        </li>
                      </ul>
                    </section>
                    <section class="column">
                      <h5 style="text-align:center">{{ $t('aboutus.2AD773@Restaurant_Details') }}</h5>
                      <ul>
                        <li>
                          <span
                              class="name"
                          >{{ $t('backend_authrity_authrityList.C90CD7@restaurant_tab') }}</span>:
                          <span>{{ v.content.restaurant_name }}</span>
                        </li>
                        <li>
                          <span class="name">{{ $t('restaurant_buy_genner.FE6497@store') }}</span>:
                          <span>{{ v.content.shop_name }}</span>
                        </li>
                        <li>
                          <span
                              class="name"
                          >{{ $t('restaurant_supplier_supplierList.E84BAF@address') }}</span>:
                          <span>{{ v.content.addr }}</span>
                        </li>
                        <li>
                          <span
                              class="name"
                          >{{ $t('restaurant_supplier_addsuppluer.0C63A5@linkman') }}</span>:
                          <span>{{ v.content.linkman }}</span>
                        </li>
                      </ul>
                    </section>
                  </section>
                </el-card>
              </div>
              <!-- 自己 -->
              <div class="userRight" v-else>
                <!-- 消息动画 -->
                <!--                <i-->
                <!--                    class="el-icon-loading"-->
                <!--                    v-if="lodingShow(v.code)"-->
                <!--                    style="font-size:18px;margin-right:5px"-->
                <!--                ></i>-->
                <i
                    class="el-icon-warning"
                    style="font-size:18px;margin-right:5px;cursor: pointer;"
                    v-if="errShow(v.code)"
                    @click="resend(v.code)"
                ></i>
                <!-- 消息 -->
                <el-tooltip placement="top" v-if="v.push_type=='chat_msg' && v.content.indexOf('採購訂單')<0">
                  <div slot="content">
                    <el-popconfirm :title="$t('public.del_message')"
                                   :confirm-button-text="$t('public.3B45A2@confirm')"
                                   :cancel-button-text="$t('public.836FD4@cancel')"
                                   @confirm="delMessage(v.rec_id)">
                      <el-button slot="reference" size="small" type="danger">
                        {{ $t('public.1FBB5E@btn_delete') }}
                      </el-button>
                    </el-popconfirm>
                  </div>
                  <div class="msg" v-html="v.content"></div>
                </el-tooltip>
                <div class="msg" v-if="v.push_type=='chat_msg' && v.content.indexOf('採購訂單')>=0"
                     v-html="v.content"></div>
                <!--         文件       -->
                <div class="msg" v-if="v.push_type=='chat_doc'"
                     v-html="v.content.substring(v.content.lastIndexOf('/')+1)" style="cursor:pointer"
                     @click="downloadDoc(v.content)">
                </div>
                <!-- 图片 -->
                <el-tooltip placement="top">
                  <div slot="content">
                    <el-popconfirm :title="$t('public.del_message')"
                                   :confirm-button-text="$t('public.3B45A2@confirm')"
                                   :cancel-button-text="$t('public.836FD4@cancel')"
                                   @confirm="delMessage(v.rec_id)">
                      <el-button slot="reference" size="small" type="danger">
                        {{ $t('public.1FBB5E@btn_delete') }}
                      </el-button>
                    </el-popconfirm>
                  </div>
                  <div class="msg-img" v-if="v.push_type=='chat_img'">
                    <el-image
                        class="flex"
                        style="max-width: 100px; max-height: 100px; min-width:50px;min-height:50px;"
                        :preview-src-list="[v.content]"
                        :src="v.imgUrl||v.content"
                        fit="cover"
                    >
                      <div slot="error" class="image-slot">
                        {{ $t('aboutus.0DBD9D@Failed_to_load') }}
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                  </div>
                </el-tooltip>
                <!-- 样本 -->
                <el-card class="sampleForm" v-if="v.push_type=='req_sample'">
                  <h3
                      style="text-align: center;margin-bottom: 15px;"
                  >{{ $t('aboutus.B4F293@Request_sample') }}</h3>
                  <section class="content">
                    <section class="column">
                      <h5 style="text-align:center">{{ $t('aboutus.72AB93@Product_Details') }}</h5>
                      <ul>
                        <li>
                          <span class="name">{{ $t('restaurant_inventory.0009CF@product_name') }}</span>:
                          <span>{{ v.content.goods_name }}</span>
                        </li>
                        <li>
                          <span
                              class="name"
                          >{{ $t('restaurant_supplier_addSupProduct.16EBEC@price') }}</span>:
                          <span>{{ v.content.price }}</span>
                        </li>
                        <li>
                          <span
                              class="name"
                          >{{ $t('supplier-produceManage-publishProduct.4E6A29@Commodity_unit') }}</span>:
                          <span>{{ v.content.unit_str }}</span>
                        </li>
                      </ul>
                    </section>
                    <section class="column">
                      <h5 style="text-align:center">{{ $t('aboutus.2AD773@Restaurant_Details') }}</h5>
                      <ul>
                        <li>
                          <span
                              class="name"
                          >{{ $t('backend_authrity_authrityList.C90CD7@restaurant_tab') }}</span>:
                          <span>{{ v.content.restaurant_name }}</span>
                        </li>
                        <li>
                          <span class="name">{{ $t('restaurant_buy_genner.FE6497@store') }}</span>:
                          <span>{{ v.content.shop_name }}</span>
                        </li>
                        <li>
                          <span
                              class="name"
                          >{{ $t('restaurant_supplier_supplierList.E84BAF@address') }}</span>:
                          <span>{{ v.content.addr }}</span>
                        </li>
                        <li>
                          <span
                              class="name"
                          >{{ $t('restaurant_supplier_addsuppluer.0C63A5@linkman') }}</span>:
                          <span>{{ v.content.linkman }}</span>
                        </li>
                      </ul>
                    </section>
                  </section>
                </el-card>
                <!-- 报价 -->
                <el-card class="priceForm" v-if="v.push_type=='req_price'">
                  <h3
                      style="text-align: center;margin-bottom: 15px;"
                  >{{ $t('aboutus.375CE0@Request_a_special_offer') }}</h3>
                  <section style="margin-bottom: 10px;">
                    <h5 style="text-align:center">{{ $t('aboutus.9301F0@Quotation_Information') }}</h5>
                    <ul>
                      <li>
                        <span class="name">{{ $t('aboutus.2E2D92@Quantity_of_Order') }}</span>:
                        <span>{{ v.content.order_num }}</span>
                      </li>
                      <li>
                        <span class="name">{{ $t('aboutus.F5021B@Target_Price') }}</span>:
                        <span>{{ v.content.req_price }}</span>
                      </li>
                      <li>
                        <span class="name">{{ $t('aboutus.035886@Repeat_Orders') }}</span>:
                        <span>{{
                            v.content.reorder == 'Yes' ? $t('aboutus.980E0D@yes') : $t('aboutus.A03514@no')
                          }}</span>
                      </li>
                    </ul>
                  </section>
                  <section style=" display: flex;justify-content: space-between;">
                    <section class="column">
                      <h5 style="text-align:center">{{ $t('aboutus.72AB93@Product_Details') }}</h5>
                      <ul>
                        <li>
                          <span class="name">{{ $t('restaurant_inventory.0009CF@product_name') }}</span>:
                          <span>{{ v.content.goods_name }}</span>
                        </li>
                        <li>
                          <span
                              class="name"
                          >{{ $t('restaurant_supplier_addSupProduct.16EBEC@price') }}</span>:
                          <span>{{ v.content.price }}</span>
                        </li>
                        <li>
                          <span
                              class="name"
                          >{{ $t('supplier-produceManage-publishProduct.4E6A29@Commodity_unit') }}</span>:
                          <span>{{ v.content.unit_str }}</span>
                        </li>
                      </ul>
                    </section>
                    <section class="column">
                      <h5 style="text-align:center">{{ $t('aboutus.2AD773@Restaurant_Details') }}</h5>
                      <ul>
                        <li>
                          <span
                              class="name"
                          >{{ $t('backend_authrity_authrityList.C90CD7@restaurant_tab') }}</span>:
                          <span>{{ v.content.restaurant_name }}</span>
                        </li>
                        <li>
                          <span class="name">{{ $t('restaurant_buy_genner.FE6497@store') }}</span>:
                          <span>{{ v.content.shop_name }}</span>
                        </li>
                        <li>
                          <span
                              class="name"
                          >{{ $t('restaurant_supplier_supplierList.E84BAF@address') }}</span>:
                          <span>{{ v.content.addr }}</span>
                        </li>
                        <li>
                          <span
                              class="name"
                          >{{ $t('restaurant_supplier_addsuppluer.0C63A5@linkman') }}</span>:
                          <span>{{ v.content.linkman }}</span>
                        </li>
                      </ul>
                    </section>
                  </section>
                </el-card>
                <!-- 头像 -->
                <el-avatar
                    shape="square"
                    style="margin-left:15px;min-width:40px"
                    :size="40"
                    :src="v.avatar"
                ></el-avatar>
              </div>
            </li>
          </ul>
          <div v-else class="flex-cc" style="height:530px;background-color: #fff;"></div>
          <section class="messageAlert">
            <el-tag
                color="#fff"
                @click.native="messageDown"
                v-if="messageAlert"
                class="el-icon-download messageAlert"
            >{{ $t('aboutus.8DA31F@New_news') }}
            </el-tag>
          </section>
          <section class="newMessage">
            <el-tag
                color="#fff"
                @click.native="messageUp"
                v-if="messageUpShow"
                class="el-icon-upload2 newMessage"
            >{{ unread_num }}{{ $t('aboutus.3DC252@new_message') }}
            </el-tag>
          </section>
        </el-main>
        <!-- 消息框 -->
        <el-footer
            height="210px"
            :style="{'box-shadow':Boolean(room_id)?'0 -8px 8px -8px rgba(0, 0, 0, .1)':'none'}"
        >
          <section v-if="Boolean(room_id)">
            <section v-if="faceShow" class="face">
              <ul class="faceContent">
                <li v-for="(v,i) in faceList" :key="i" @click="faceContent(v,i)">{{ v }}</li>
              </ul>
            </section>
            <section class="attach">
              <i
                  style="font-size:30px"
                  class="fa fa-smile-o"
                  @click="faceShow = !faceShow"
                  aria-hidden="true"
              ></i>
              <el-upload action="no" :show-file-list="false" :http-request="uploadFile">
                <i style="font-size:30px;margin-left:10px;" class="el-icon-picture"></i>
              </el-upload>
              <el-upload action="no" accept=".dox, .docx, .xls, .xlsx, .pdf" :show-file-list="false"
                         :http-request="uploadDoc">
                <i style="font-size:30px;margin-left:10px;" class="el-icon-upload2"></i>
              </el-upload>
            </section>
            <textarea v-model="msg" rows="4" maxlength="1000" @keydown="keySend($event)"></textarea>
            <section class="msg-bottom">
              <span>Shift+Enter {{ $t('aboutus.88E89A@Return') }}</span>
              <button class="send" @click="send">{{ $t('aboutus.2FB613@Send') }}</button>
            </section>
          </section>
        </el-footer>
      </el-container>
    </el-container>
  </section>
</template>

<script>
import {Promise} from "q";
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {time} from "@/utils/dateTime";
import md5 from "@/utils/md5";
import {mapState} from "vuex";
import emojisData from "@/assets/emojis.json";
import {getlocalStorage} from "../../utils/localStorage";

export default {
  //import引入的组件需要注入到对象中才能使用
  name: "chatroom",
  components: {},

  props: {},
  data() {
    //这里存放数据
    return {
      keywords: "",
      msg: "",
      ChatList: [],
      room_name: "",
      msgList: [],
      loading: false,
      room_id: "",
      ImageUrl: "",
      badgeShow: [],
      messageAlert: false,
      emoji: [],
      first_id: 0,
      unread_num: "",
      messageUpShow: false,
      myAvatar: "",
      md5Code: [],
      errCode: [],
      keyIndex: [],
      wsOpen: true,
      faceList: [],
      faceShow: false
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState("websocket", {
      chat_msg: state => state.chat_msg,
      chat_img: state => state.chat_img,
      chat_list: state => state.chat_list,
      chat_inform: state => state.chat_inform,
      req_sample: state => state.req_sample,
      req_price: state => state.req_price
    })
  },
  //监控data中的数据变化
  watch: {
    chat_msg: {
      handler(newData, oldData) {
        console.log("新消息信息", newData);
        if (newData.room_id == this.room_id) {
          let flag = true;
          let index;
          this.msgList.forEach((v, i) => {
            if (v.code == newData.push_data.code) {
              flag = false;
              index = i;
            }
          });
          if (flag) {
            this.msgList.push(newData.push_data);
          } else {
            if (this.errCode.indexOf(newData.push_data.code) > -1) {
              this.msgList.splice(index, 1);
              this.msgList.push(newData.push_data);
            } else {
              this.msgList.splice(index, 1, newData.push_data);
            }
          }
          this.md5Code.forEach((v, i) => {
            if (v == newData.push_data.code) {
              this.md5Code.splice(i, 1);
            }
          });
          this.errCode.forEach((v, i) => {
            if (v == newData.push_data.code) {
              this.errCode.splice(i, 1);
            }
          });
          if (Boolean(this.$el.querySelector(".msgContent").scrollHeight > 530 && this.$el.querySelector(".msgContent").scrollTop + 531 + 50 < this.$el.querySelector(".msgContent").scrollHeight && this.messageAlert == false)) {
            this.messageAlert = true;
          } else {
            this.goDown();
          }
        }
      },
      deep: true
    },
    chat_img: {
      handler(newData, oldData) {
        console.log("新的图片信息", newData);
        if (newData.room_id == this.room_id) {
          let flag = true;
          let index;
          this.msgList.forEach((v, i) => {
            if (v.code == newData.push_data.code) {
              flag = false;
              index = i;
            }
          });
          if (flag) {
            this.msgList.push(newData.push_data);
          } else {
            if (this.errCode.indexOf(newData.push_data.code) > -1) {
              this.msgList.splice(index, 1);
              this.msgList.push(newData.push_data);
            } else {
              // this.msgList.splice(index, 1, newData.push_data);
              this.msgList[index] = Object.assign(
                  this.msgList[index],
                  newData.push_data
              );
            }
          }
          this.md5Code.forEach((v, i) => {
            if (v == newData.push_data.code) {
              this.md5Code.splice(i, 1);
            }
          });
          this.errCode.forEach((v, i) => {
            if (v == newData.push_data.code) {
              this.errCode.splice(i, 1);
            }
          });
          if (Boolean(this.$el.querySelector(".msgContent").scrollHeight > 530 && this.$el.querySelector(".msgContent").scrollTop + 531 + 50 < this.$el.querySelector(".msgContent").scrollHeight && this.messageAlert == false)) {
            this.messageAlert = true;
          } else {
            this.goDown();
          }
        }
      },
      deep: true
    },
    chat_list: {
      handler(newData, oldData) {
        console.log("新的列表信息", newData);
        if (!this.keywords) {
          this.ChatList = newData.push_data;
          // 遍历新推得列表遍历如果是当前聊天室都不显示，如角标大于零调用清楚角标接口
          newData.push_data.forEach((v, i) => {
            if (v.room_id == this.room_id) {
              this.$set(this.badgeShow, i, false);
              if (v.badge > 0) {
                this.$http.clearChatListBadge({
                  room_id: this.room_id
                });
              }
            } else {
              if (v.badge > 0) {
                this.$set(this.badgeShow, i, true);
              } else {
                this.$set(this.badgeShow, i, false);
              }
            }
          });
        }
      },
      deep: true
    },
    req_sample: {
      handler(newData, oldData) {
        console.log("新的样本信息", newData);
        if (newData.room_id == this.room_id) {
          this.msgList.push(newData.push_data);
          if (Boolean(this.$el.querySelector(".msgContent").scrollHeight > 530 && this.$el.querySelector(".msgContent").scrollTop + 531 + 50 < this.$el.querySelector(".msgContent").scrollHeight && this.messageAlert == false)) {
            this.messageAlert = true;
          } else {
            this.goDown();
          }
        }
      },
      deep: true
    },
    req_price: {
      handler(newData, oldData) {
        console.log("新的价格信息", newData);
        if (newData.room_id == this.room_id) {
          this.msgList.push(newData.push_data);
          if (Boolean(this.$el.querySelector(".msgContent").scrollHeight > 530 && this.$el.querySelector(".msgContent").scrollTop + 531 + 50 < this.$el.querySelector(".msgContent").scrollHeight && this.messageAlert == false)) {
            this.messageAlert = true;
          } else {
            this.goDown();
          }
        }
      },
      deep: true
    },
    chat_inform: {
      handler(newData, oldData) {
        console.log("新通知", newData);
        if (newData.room_id == this.room_id) {
          if (newData.push_data.badge_sum > 0) {
            this.clearChatInform();
          }
        }
      },
      deep: true
    },
    $route(to, from) {
      if (this.room_id != to.query.room_id) {
        this.room_id = to.query.room_id;
        this.ChatList.forEach((v, i) => {
          if (v.room_id == this.room_id) {
            this.clickChatList(v, i);
          }
        });
      }
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  async created() {
    // 获取聊天列表
    await this.getChatList();
    if (this.$route.query.room_id) {
      this.room_id = this.$route.query.room_id;
      this.ChatList.forEach((v, i) => {
        if (v.room_id == this.room_id) {
          this.clickChatList(v, i);
        }
      });
    }
    if (Boolean(this.$getlocalStorage("supplierUserInfo"))) {
      this.myAvatar = JSON.parse(this.$getlocalStorage("supplierUserInfo")).avatar;
    }
    // this.global.websocketclose(this.onOpen);
    // this.global.websocketOpen(this.onOpen);
    // this.global.websocketerror(this.onError);
    Object.keys(emojisData).forEach((key, i) => {
      this.faceList.push(emojisData[key].char);
    });
  },
  //方法集合
  methods: {
    //下載文件
    downloadDoc(url) {
      let fileName = url.substring(url.lastIndexOf('/') + 1)
      this.downloadFileByName(url, fileName)
    },
    //刪除按鈕
    delMessage(rec_id) {
      let ary = this.msgList.filter(s => s.rec_id != rec_id)
      this.$http.delMessage({
        rec_id: rec_id,
        project: 0,
      }).then(res => {
        if (res.errno == 0) {
          this.msgList = ary
        }
      })
    },
    // 获取聊天列表
    getChatList() {
      return new Promise(resolve => {
        this.$http.getChatList({
          keywords: this.keywords
        }).then(res => {
          console.log("聊天列表", res);
          this.ChatList = res.data;
          res.data.forEach((v, i) => {
            if (v.room_id == this.room_id) {
              this.$set(this.badgeShow, i, false);
              if (v.badge > 0) {
                this.$http.clearChatListBadge({
                  room_id: this.room_id
                });
              }
            } else {
              if (v.badge > 0) {
                this.$set(this.badgeShow, i, true);
              } else {
                this.$set(this.badgeShow, i, false);
              }
            }
          });
          resolve();
        });
      });
    },
    // 发送消息loding动画
    lodingShow(val) {
      if (!val) return false;
      if (this.md5Code.indexOf(val) > -1 && this.errCode.indexOf(val) < 0) {
        return true;
      } else {
        return false;
      }
    },
    errShow(val) {
      if (!val) return false;
      if (this.errCode.indexOf(val) > -1) {
        return true;
      } else {
        return false;
      }
    },
    // 点击左侧聊天列表
    async clickChatList(row, index) {
      console.log("切换聊天室");
      this.messageUpShow = false;
      this.messageAlert = false;
      this.first_id = 0;
      this.room_id = row.room_id;
      //同时更改url参数（标识作用）
      this.$router.push({
        name: "chatroom",
        query: {room_id: row.room_id}
      });
      this.$store.commit("setRoomId", row.room_id);
      this.room_name = row.room_name;
      this.unread_num = "";
      this.first_unread_id = "";
      this.clearChatInform();
      const res = await this.getChatRecord();
      this.goDown();

      //获取完聊天记录清楚角标
      if (row.badge > 0) {
        this.$set(this.badgeShow, index, false);
        // 清楚角标接口
        this.$http.clearChatListBadge({
          room_id: row.room_id
        });
      }
    },
    //清空
    clearChatInform(v) {
      this.$http.clearChatInform({
        room_id: this.room_id
      });
    },
    //获取聊天记录
    getChatRecord(e) {
      this.loading = true;
      return new Promise(resolve => {
        let param = {
          first_id: this.first_id,
          room_id: this.room_id
        };
        this.$http.getChatRecord(param).then(res => {
          if (e) {
            // 触顶追加
            let oldHeight = this.$el.querySelector(".msgContent").scrollHeight;
            this.msgList.unshift(...res.data.list);
            this.$nextTick(() => {
              this.$el.querySelector(".msgContent").scrollTop =
                  this.$el.querySelector(".msgContent").scrollHeight -
                  oldHeight -
                  45;
            });
          } else {
            // 第一次获取
            this.msgList = res.data.list;
            if (res.data.unread_num > 10) {
              this.unread_num = res.data.unread_num;
              this.messageUpShow = true;
              this.first_unread_id = res.data.first_unread_id;
            }
          }
          this.first_id = res.data.first_id;
          this.loading = false;
          resolve(res.data);
        });
      });
    },
    // 到底部
    goDown() {
      this.$nextTick(() => {
        this.$el.querySelector(".msgContent").scrollTop = this.$el.querySelector(".msgContent").scrollHeight;
      });
    },
    //上傳文件
    uploadDoc(param) {
      const file = param.file;
      // 是否大于30mb
      if (file.size / 1048576 > 30) {
        this.$message.error(this.$t("aboutus.7C0CF6@Upload_failed"));
        return
      }
      let that = this
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function (event) {
        that.$confirm("", that.$t("public.553159@tips"), {
          dangerouslyUseHTMLString: true,
          message: `
            <div style='display: flex;flex-direction: column;justify-content: center;align-items: center;'>
            <div style='font-size:16px;margin-bottom:20px;'>${that.$t("aboutus.9397B8@Click_OK_to_Send_the_Doc")}</div>
            <img style='max-height:350px;max-width:350px' src="${
              this.result
          }" alt="">
            </div>`,
          confirmButtonText: that.$t("public.3B45A2@confirm"),
          cancelButtonText: that.$t("public.836FD4@cancel")
        }).then(() => {
          let code = md5(that.getRanNum());
          that.md5Code.push(code);

          function sortNumber(a, b) {
            return a - b
          }

          let authmd5 = md5(JSON.stringify(JSON.parse(getlocalStorage('supplierroute_id')).sort(sortNumber)) + JSON.stringify(JSON.parse(getlocalStorage('supplierbtn_id')).sort(sortNumber)) + JSON.stringify(JSON.parse(getlocalStorage('supplierAuth_id_json')).sort(sortNumber)))
          const params = {
            push_type: "chat_doc",
            room_id: that.room_id,
            msg: this.result,
            code: code,
            authmd5: authmd5,
          }

          that.$http.sendMessage(params).then(res => {
            if (res) {
              that.msgList.push({
                ...params,
                avatar: this.myAvatar,
                content: res.data.image,
                display: "me",
              })
            }
          })
          that.goDown()
        })
      }
    },
    // 上传图片
    uploadFile(param) {
      const file = param.file;
      // 是否大于6mb
      if (file.size / 1024 / 1024 > 6) {
        this.$message.error(this.$t("aboutus.7C0CF5@Upload_failed"));
        return;
      }
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (event) {
        that.$confirm("", that.$t("public.553159@tips"), {
          dangerouslyUseHTMLString: true,
          message: `
            <div style='display: flex;flex-direction: column;justify-content: center;align-items: center;'>
            <div style='font-size:16px;margin-bottom:20px;'>${that.$t("aboutus.9397BF@Click_OK_to_Send_the_Picture")}</div>
            <img style='max-height:350px;max-width:350px' src="${
              this.result
          }" alt="">
            </div>`,
          confirmButtonText: that.$t("public.3B45A2@confirm"),
          cancelButtonText: that.$t("public.836FD4@cancel")
        }).then(() => {
          let code = md5(that.getRanNum());
          that.md5Code.push(code);
          // const params = {
          //   push_type: "chat_img",
          //   room_id: that.room_id,
          //   content: this.result,
          //   code
          // };
          function sortNumber(a, b) {
            return a - b
          }

          let authmd5 = md5(JSON.stringify(JSON.parse(getlocalStorage('supplierroute_id')).sort(sortNumber)) + JSON.stringify(JSON.parse(getlocalStorage('supplierbtn_id')).sort(sortNumber)) + JSON.stringify(JSON.parse(getlocalStorage('supplierAuth_id_json')).sort(sortNumber)))
          const params = {
            push_type: "chat_img",
            room_id: that.room_id,
            msg: this.result,
            code: code,
            authmd5: authmd5,
          };

          that.$http.sendMessage(params).then(res => {
            if (res) {
              that.msgList.push({
                ...params,
                avatar: this.myAvatar,
                imgUrl: res.data.image,
                display: "me",
              });
            }
          })
          that.goDown();
          // that.global.websocketsend(params);
        });
      };
    },
    // 表情包赋值
    faceContent(v, i) {
      this.msg += v;
      this.faceShow = !this.faceShow;
    },
    // 新消息提示(下)
    messageDown() {
      this.messageAlert = false;
      this.goDown();
    },
    // 新消息提示(上)
    messageUp() {
      // 将聊天窗口高度设置与第一条未读消息一致
      this.$el.querySelector(".msgContent").scrollTop = this.$el.querySelector(`div[data-msgId='${this.first_unread_id}']`).scrollTop + 5;
      this.messageUpShow = false;
    },
    // ctrl回车发送消息
    keySend(e) {
      if (!Boolean(e.keyCode == 13 || e.shiftKey)) return;
      if (e.keyCode == 13 && e.shiftKey) {
        this.msg += ""
        return
      }
      if (e.keyCode == 13)
        this.send();
    },
    // 随机唯一标识码
    getRanNum() {
      var result = [];
      for (var i = 0; i < 4; i++) {
        var ranNum = Math.ceil(Math.random() * 25); //生成一个0到25的数字
        //大写字母'A'的ASCII是65,A~Z的ASCII码就是65 + 0~25;然后调用String.fromCharCode()传入ASCII值返回相应的字符并push进数组里
        result.push(String.fromCharCode(65 + ranNum));
      }
      return result.join("") + new Date().getTime();
    },
    // 发消息
    send() {
      // md5加密
      let code = md5(this.getRanNum());
      this.md5Code.push(code);
      if (!Boolean(this.msg.replace(/\n|\r\n|\r|(^\s*)|(\s*$)/g, "").length))
        return;
      let msg = this.msg.replace(/\n|\r\n|\r/g, "<br>");
      // const params = {
      //   push_type: "chat_msg",
      //   room_id: this.room_id,
      //   content: this.msg,
      //   code
      // };
      function sortNumber(a, b) {
        return a - b
      }

      let authmd5 = md5(JSON.stringify(JSON.parse(getlocalStorage('supplierroute_id')).sort(sortNumber)) + JSON.stringify(JSON.parse(getlocalStorage('supplierbtn_id')).sort(sortNumber)) + JSON.stringify(JSON.parse(getlocalStorage('supplierAuth_id_json')).sort(sortNumber)))

      const params = {
        push_type: "chat_msg",
        room_id: this.room_id,
        msg: this.msg,
        code: code,
        authmd5: authmd5,
      };
      this.msgList.push({
        push_type: "chat_msg",
        room_id: this.room_id,
        content: msg,
        code,
        avatar: this.myAvatar,
        display: "me"
      });
      this.$http.sendMessage(params)
      this.msg = "";
      this.goDown();
      // this.msg = "";
      // this.goDown();
      // this.global.websocketsend(params);
    },
    // 失败重发
    resend(code) {
      let data;
      if (this.wsOpen) {
        this.msgList.forEach((v, i) => {
          if (v.code == code) {
            data = v;
          }
        });
        const params = {
          push_type: data.push_type,
          room_id: data.room_id,
          content: data.content,
          code: data.code
        };
        this.goDown();
        console.log(params);
        // this.global.websocketsend(params);
      }
    },
    // 滑动滚动条事件
    handleScroll(e, flag) {
      // 触顶时追加聊天记录
      if (
          e.target.scrollTop == 0 &&
          Boolean(this.$el.querySelector(".msgContent").scrollHeight > 530) &&
          this.first_id > 0
      ) {
        this.getChatRecord(true);
      }
      // 滑动到新消息出现时，新消息标签消失
      if (this.$el.querySelector(".msgContent").scrollHeight < e.target.scrollTop + 575 && this.messageAlert == true) {
        this.messageAlert = false;
      }
      // 上方提示新消息时当滑动条滚到第一条未读消失时自动消失
      if (this.first_unread_id && this.$el.querySelector(".msgContent").scrollTop == this.$el.querySelector(`div[data-msgId='${this.first_unread_id}']`).scrollTop + 5) {
        this.messageUpShow = false;
      }
    },
    onClose() {
      this.wsOpen = false;
      if (Boolean(this.md5Code.length)) {
        this.errCode = [...this.md5Code];
      }
    },
    onOpen() {
      this.wsOpen = true;
    },
    onError() {
      this.wsOpen = false;
      if (Boolean(this.md5Code.length)) {
        this.errCode = [...this.md5Code];
      }
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
  },
  beforeCreate() {
  }, //生命周期 - 创建之前
  beforeMount() {
  }, //生命周期 - 挂载之前
  beforeUpdate() {
  }, //生命周期 - 更新之前
  updated() {
  }, //生命周期 - 更新之后
  beforeDestroy() {
    this.$store.commit("setRoomId", "");
  }, //生命周期 - 销毁之前
  destroyed() {
  }, //生命周期 - 销毁完成
  activated() {
  } //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' src='./chatroom.scss'>
//@import url(); 引入公共css类
</style>
